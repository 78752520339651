import { mdiAlertCircle, mdiCheckboxMarkedCircle, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import { getCurrentDate, getResourceType, getServiceData, getServiceDetail, getUserServiceDetail, serviceConfirm } from "data/CommonFunction";
import { refreshTimeAtom } from "data/Store";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import MakeToast from "hooks/MakeToast";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import "./MgmtService.scss";
import LogViewer from "components/logviewer/LogViewer";

export const MgmtService = () => {
  const [showAgreeUsers, setShowAgreeUsers] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabDetailData, setTabDetailData] = useState();
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [yamlText, setYamlText] = useState("");
  const [detailModalData, setDetailModalData] = useState();
  const [resourceType, setResourceType] = useState({
    pod: [],
    deployment: [],
    service: [],
  });

  const headerItems = [
    {
      name: "번호",
      key: "serviceNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 이름",
      key: "serviceName",
      width: "500px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "사용자",
      key: "userId",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "요청일시",
      key: "regdate",
      width: "280px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스 상태",
      key: "serviceState",
      type: "serviceStatus",
      action: (row) => goResourceDetail(row),
    },
    {
      name: "서비스 등록 승인/거절",
      key: "agreeYn",
      width: "220px",
      type: "agreeYn",
      action: (type, row) => agreeHandler(type, row),
    },
    {
      name: "서비스 사용 요청자 수/승인자 수",
      key: "agreeUsers",
      width: "300px",
      type: "agreeUsers",
      action: (row) => clickedAgreeUsers(row),
    },
    {
      name: "",
      key: "action",
      center: "no",
      width: "115px",
      type: "detail",
      action: (row) => goDetail(row),
    },
  ];
  const modalResourcePodTableHeaderItems = [
    {
      name: "이름",
      key: "name",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "상태",
      key: "status",
      width: "178px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "생성일시",
      key: "regDate",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
  ];

  const modalResourceTableHeaderItems = (type) => {
    return [
      {
        name: "이름",
        key: "name",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "네임스페이스",
        key: "namespace",
        width: "178px",
        center: "no",
        type: "text",
        action: (row) => clickedRow(row, type),
      },
      {
        name: "생성일시",
        key: "regDate",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedRow(row, type),
      },
    ];
  };

  const modalTableHeaderItems = [
    {
      name: "번호",
      key: "resourceNo",
      width: "90px",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
    {
      name: "이름",
      key: "resourceName",
      width: "250px",
      center: "no",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
    {
      name: "우선순위",
      key: "resourceOrder",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
  ];

  const requestListHeader = [
    {
      name: "번호",
      key: "no",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "유저 이름(ID)",
      key: "userId",
      width: "400px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "요청일시",
      key: "regdate",
      width: "360px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "요청 상태",
      key: "agreeStatus",
      type: "agreeStatus",
      width: "212px",
      action: (row) => clickedRow(row),
    },
    {
      name: "승인/거절",
      key: "agreeYn",
      width: "120px",
      type: "agreeYn",
      action: (type, row) => agreeHandler(type, row),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const getDetailHeader = (type) => {
    const headerItem = type === "pod" ? modalResourcePodTableHeaderItems : modalResourceTableHeaderItems(type);

    return headerItem;
  };

  const clickedDetailRow = (row, type) => {
    switch (type) {
      case "deployment":
        setTabDetailData(showResourceModal?.deployment[row.no]);
        break;
      case "service":
        setTabDetailData(showResourceModal?.service[row.no]);
        break;
      case "ingress":
        setTabDetailData(showResourceModal?.ingress[row.no]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[row.no]);
        break;
    }
  };

  const [showAgreeDialog, setShowAgreeDialog] = useState(false);
  const [agreeState, setAgreeState] = useState("");
  const agreeHandler = (type, row) => {
    setAgreeState(type);
    setShowAgreeDialog(row);
  };
  const agreeAction = () => {
    const confirmAction = async () => {
      return showAgreeDialog.userInService
        ? await serviceConfirm(showAgreeDialog.serviceNo, agreeState === "Y" ? 1 : 2, showAgreeDialog.serviceName, null)
        : await serviceConfirm(showAgreeDialog.mecServiceNo, agreeState === "Y" ? 1 : 2, showAgreeDialog.serviceName, showAgreeDialog.mecUserNo);
    };

    confirmAction().then((result) => {
      if (result) {
        setShowAgreeDialog(false);
        MakeToast("success", `정상적으로 ${agreeState === "Y" ? "승인" : "거절"}이 완료되었습니다.`);
        setServiceData();
      }
    });
  };

  const getAgreeDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            {agreeState === "Y" ? (
              <div className="flexRow Y">
                <Icon path={mdiCheckboxMarkedCircle} size="36px" color="#2C9AFF" />
                <p>요청승인</p>
              </div>
            ) : (
              <div className="flexRow N">
                <Icon path={mdiAlertCircle} size="36px" color="#F06262" />
                <p>요청거절</p>
              </div>
            )}
          </div>

          <div className="sm-dialog-contents">
            <p>
              {showAgreeDialog?.serviceName}
              {" 서비스 요청을 "}
              {agreeState === "Y" ? "승인" : "거절"}
              {" 하시겠습니까?"}
            </p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowAgreeDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => agreeAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const clickedAgreeUsers = (v) => {
    setRequestServiceName(v.serviceName);
    setShowAgreeUsers(v.userInService);
  };
  const goResourceDetail = (v) => {
    getServiceDetail(v.userNo, v.serviceNo).then((detailResp) => {
      setTabValue(0);
      setTabDetailData(detailResp.pod[0]);
      setShowResourceModal(detailResp);
    });
  };

  const handleResourceTabValueChange = (event, newValue) => {
    switch (newValue) {
      case 1:
        setTabDetailData(showResourceModal?.deployment[0]);
        break;
      case 2:
        setTabDetailData(showResourceModal?.service[0]);
        break;
      case 3:
        setTabDetailData(showResourceModal?.ingress[0]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[0]);
        break;
    }
    setTabValue(newValue);
  };
  const [serviceInfo, setServiceInfo] = useState();
  const [requestServiceName, setRequestServiceName] = useState("");
  const setRefreshTime = useSetAtom(refreshTimeAtom);

  const setServiceData = useCallback(() => {
    getServiceData().then((serviceData) => {
      setServiceInfo(serviceData);
      setShowAgreeUsers((users) =>
        users.length > 0 ? serviceData.serviceList.filter((row) => row.serviceNo === users[0].mecServiceNo)[0].userInService : users
      );
    });

    getResourceType().then((resourceTypeResp) => {
      setResourceType(resourceTypeResp);
    });

    setRefreshTime(getCurrentDate());
  }, [setRefreshTime]);

  useEffect(() => {
    setServiceData();
  }, [setServiceData]);

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" className="panel-wrapper flexRow" hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const goDetail = (v) => {
    setYamlText("");
    getUserServiceDetail(v.serviceNo).then((detailResp) => {
      const modalDesc = { description: v.serviceDescription };
      setTabValue(0);
      setDetailModalData(detailResp);
      setShowDetailModal(modalDesc);
      setRefreshTime(getCurrentDate());
    });
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
    if (detailModalData.length > 0) {
      switch (newValue) {
        case 0:
        default:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.pod.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
        case 1:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.deployment.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
        case 2:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.service.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
      }
    }
  };

  return (
    <div className="page">
      {showAgreeUsers && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">요청목록 ({requestServiceName})</p>
              <IconButton onClick={() => setShowAgreeUsers(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <CustomTable
                header={getTableHeader(requestListHeader)}
                rowData={showAgreeUsers ? showAgreeUsers : []}
                perPage={10}
                rowClickCallBack={clickedRow}
              />
            </div>
          </div>
        </div>
      )}
      {showAgreeDialog && getAgreeDialog()}
      {showDetailModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">서비스 정보</p>
              <IconButton onClick={() => setShowDetailModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="desc-sec">{showDetailModal.description}</div>
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                </Tabs>
              </div>

              <CustomTabPanel value={tabValue} index={0}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.pod.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.deployment.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.service.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}
      {showResourceModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">서비스 상세</p>
              <IconButton onClick={() => setShowResourceModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleResourceTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                  <Tab label="인그레스" />
                </Tabs>
              </div>

              <CustomTabPanel value={tabValue} index={0}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("pod"))}
                    rowData={showResourceModal?.podList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "pod")}
                  />
                </div>
                {tabValue === 0 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">노드</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.nodeName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">호스트 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.status.hostIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("deployment"))}
                    rowData={showResourceModal?.deployList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "deployment")}
                  />
                </div>
                {tabValue === 1 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">레플리카셋</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.replicas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">상태</p>
                            </td>
                            <td>
                              <p className="center">
                                {"Ready Replicas: "}
                                {tabDetailData?.status.readyReplicas}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("service"))}
                    rowData={showResourceModal?.serviceList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "service")}
                  />
                </div>
                {tabValue === 2 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클러스터 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.clusterIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>포트</p>
                            </td>
                            <td>
                              {tabDetailData?.spec?.ports?.map((portRow, idx) => {
                                return (
                                  <div className="black-box" key={"port_" + idx}>
                                    {Object.entries(portRow).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">서비스 타입</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.type}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("ingress"))}
                    rowData={showResourceModal?.ingressList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "ingress")}
                  />
                </div>
                {tabValue === 3 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클래스 이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.ingressClassName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">링크</p>
                            </td>
                            <td>
                              {tabDetailData &&
                                tabDetailData.linkList.map((link) => {
                                  return (
                                    <p className="center">
                                      <a href={link.linkHref} target="_blank" rel="noreferrer">
                                        {link.linkName}
                                      </a>
                                    </p>
                                  );
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}
      <div className="page-inner">
        <div className="mgmtsv-wrap flexCol">
          <ComponentHeader title="서비스 관리" />
          <div className="mgmtsv-mgmt-header box-info flexRow">
            <div className="left-wrap flexRow">
              <p className="wrap-title">서비스 상태</p>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#15EA6A" }} />
                  실행중
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.running)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#2C9AFF" }} />
                  승인
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.approved)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  요청
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.requested)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#F06262" }} />
                  거절
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.rejected)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span />
                  대기
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.deleted)}</p>
              </div>
            </div>
            <div className="right-wrap flexRow">
              <p className="wrap-title">서비스 사용 요청</p>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  요청
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.reqUserCnt)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#2C9AFF" }} />
                  승인
                </p>
                <p className="value">{getCommaNumber(serviceInfo?.apprUserCnt)}</p>
              </div>
            </div>
          </div>
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={serviceInfo?.serviceList} perPage={15} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MgmtService;
