import "./Login.scss";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { useState } from "react";
import SignUpModal from "components/signup_modal/SignUpModal";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { userAuthAtom } from "data/Store";
import axios from "axios";
import MakeToast from "hooks/MakeToast";
import GigaMEC from "../../assets/images/login_icon.png";

const Login = () => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const [idError] = useState(false);
  const [pwError] = useState(false);
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const setUserAuth = useSetAtom(userAuthAtom);
  const loginConfirm = () => {
    axios
      .post("/api/user/login", { userId: inputId, userPasswd: inputPw })
      .then((loginResp) => {
        setUserAuth(loginResp.data);
        navigate("/");
      })
      .catch((err) => {
        MakeToast("fail", "로그인에 실패하였습니다.");
      });
  };

  return (
    <div className="page login-page">
      {showSignUpModal && <SignUpModal isShowHandler={setShowSignUpModal} />}
      <div className="login-box-wrap">
        <div className="login-box">
          <div className="login-input-wrap">
            <div className="logo">
              <img src={GigaMEC} alt="GigaMEC" />
            </div>
            <label>
              <p>
                아이디
                <span className="require"></span>
              </p>
              <input
                type="text"
                placeholder="입력하세요."
                className={idError ? "error" : ""}
                value={inputId}
                onChange={(e) => setInputId(e.target.value)}
              />
              <div className="error-msg">{idError && <p>에러메세지</p>}</div>
            </label>
            <label>
              <p>
                비밀번호
                <span className="require"></span>
              </p>
              <div className="password-wrap">
                <input
                  className={pwError ? "error" : ""}
                  type={passwordVisible ? "text" : "password"}
                  placeholder="입력하세요."
                  value={inputPw}
                  onChange={(e) => setInputPw(e.target.value)}
                  onKeyUp={(e) => {
                    e.key === "Enter" && loginConfirm();
                  }}
                />
                <Icon
                  path={passwordVisible ? mdiEye : mdiEyeOff}
                  size="20px"
                  color={pwError ? "#f06262" : "#9199A1"}
                  onClick={togglePasswordVisibility}
                  value={inputPw}
                  onChange={(e) => setInputPw(e.target.value)}
                />
              </div>
              <div className="error-msg">{pwError && <p>에러메세지</p>}</div>
            </label>
            <div
              className={`main-btn ${idError || pwError ? "disabled" : ""}`}
              onClick={() => loginConfirm()}
            >
              로그인
            </div>
            <div
              className="main-btn signup"
              onClick={() => setShowSignUpModal(true)}
            >
              회원가입
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
