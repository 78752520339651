import { mdiAlertCircle, mdiCheckboxMarkedCircle, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import CustomCodeMirror from "components/codemirror/CustomCodeMirror";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import {
  addMyApp,
  controlApp,
  deleteApp,
  deleteResource,
  getAppDetail,
  getAppList,
  getCurrentDate,
  getDefaultServiceYaml,
  getResourceType,
  getRunningAppDetail,
  getUserNsNo,
  insertResource,
  updateResource,
  updateResourceOrder,
} from "data/CommonFunction";
import { openModalAtom, refreshTimeAtom, userAuthAtom } from "data/Store";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import MakeToast from "hooks/MakeToast";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const MyMecApp = () => {
  const headerItems = [
    {
      name: "번호",
      key: "appNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "앱 이름",
      key: "appName",
      width: "500px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "파드",
      key: "appPodNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "디플로이먼트",
      key: "appDeploymentNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스",
      key: "appServiceNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "앱 상태",
      key: "appStatus",
      type: "appStatus",
      action: (row) => {
        getRunningAppDetail(row.mecUserNo, row.appNo).then((detailResp) => {
          setResourceTabValue(0);
          setTabDetailData(detailResp.pod[0]);
          setShowResourceModal(detailResp);
        });
      },
    },
    {
      name: "제어",
      key: "control",
      type: "control2",
      action: (type, row) => agreeHandler(type, row),
    },
    {
      name: "상세",
      key: "action",
      center: "no",
      width: "115px",
      type: "detail",
      action: (row) => goDetail(row),
    },
    {
      name: "삭제",
      key: "delete",
      type: "delete",
      action: (action, row) => {
        if (row.appStatus === "run") {
          MakeToast("fail", "실행 중인 앱은 편집할 수 없습니다.");
        } else {
          setShowDeleteAppDialog(row);
        }
      },
    },
  ];

  const clickedRow = (v) => {
    console.debug(v);
  };

  const [showAgreeDialog, setShowAgreeDialog] = useState(false);
  const [runState, setRunState] = useState("");
  const [resourceType, setResourceType] = useState({
    pod: [],
    deployment: [],
    service: [],
  });
  const userAuthData = useAtomValue(userAuthAtom);
  const setRefreshTime = useSetAtom(refreshTimeAtom);
  const [statusCount, setStatusCount] = useState();
  const [myAppData, setMyAppData] = useState();
  const [yamlText, setYamlText] = useState("");
  const [openModal, setOpenModal] = useAtom(openModalAtom);
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [tabDetailData, setTabDetailData] = useState();
  const [detailModalData, setDetailModalData] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showAddService, setShowAddService] = useState(false);
  const [resourceTabValue, setResourceTabValue] = useState(0);
  const [showDeleteAppDialog, setShowDeleteAppDialog] = useState();

  const initAppList = useCallback(() => {
    getAppList(userAuthData.userNo).then((appResp) => {
      setStatusCount(appResp.appStat);
      setMyAppData(appResp.appData);
    });

    getResourceType().then((resourceTypeResp) => {
      setResourceType(resourceTypeResp);
    });

    setRefreshTime(getCurrentDate());
  }, [setRefreshTime, userAuthData.userNo]);

  const addService = useCallback(() => {
    getUserNsNo(userAuthData.userNo).then((userNsNo) => {
      setShowAddService({
        appName: "",
        mecUserNsNo: userNsNo,
        appDaemonsetNum: 0,
        appDeploymentNum: 0,
        appPodNum: 0,
        appRequestCpu: 0,
        appRequestStorage: 0,
        appRequestTraffic: 0,
        appServiceNum: 0,
        secret: "",
      });
    });
  }, [userAuthData]);

  useEffect(() => {
    initAppList();

    if (openModal) {
      setOpenModal(false);
      addService();
    }
  }, [addService, initAppList, openModal, setOpenModal]);

  const agreeHandler = (type, row) => {
    setRunState(type);
    setShowAgreeDialog(row);
  };
  const agreeAction = () => {
    controlApp(runState, showAgreeDialog.appNo).then(() => {
      MakeToast("success", `정상적으로 ${runState === "run" ? "실행" : "정지"}이(가) 완료되었습니다.`);
      initAppList();
      setShowAgreeDialog(false);
    });
  };

  const getDeleteAppDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            <div className="flexRow N">
              <Icon path={mdiAlertCircle} size="18px" color="#F06262" />
              <p>{showDeleteAppDialog.appName} 삭제</p>
            </div>
          </div>

          <div className="sm-dialog-contents">
            <p>해당 앱을 삭제 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowDeleteAppDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => deleteAppAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getAgreeDialog = () => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            {runState === "run" ? (
              <div className="flexRow Y">
                <Icon path={mdiCheckboxMarkedCircle} size="36px" color="#2C9AFF" />
                <p>서비스 실행</p>
              </div>
            ) : (
              <div className="flexRow N">
                <Icon path={mdiAlertCircle} size="36px" color="#F06262" />
                <p>서비스 정지</p>
              </div>
            )}
          </div>

          <div className="sm-dialog-contents">
            <p>{runState === "run" ? "실행" : "정지"}하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowAgreeDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => agreeAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const deleteAction = () => {
    deleteResource(showDeleteDialog.resourceNo).then(() => {
      getAppDetail(showDeleteDialog.mecAppNo).then((detailResp) => {
        setDetailModalData({
          appDetail: detailResp,
          appNo: showDeleteDialog.mecAppNo,
        });
      });
      initAppList();
      setShowDeleteDialog(false);
      MakeToast("success", `정상적으로 삭제가 완료되었습니다.`);
    });
  };

  const deleteAppAction = () => {
    deleteApp(showDeleteAppDialog.appNo).then((result) => {
      if (result) {
        initAppList();
        setShowDeleteAppDialog(false);
        MakeToast("success", `정상적으로 삭제가 완료되었습니다.`);
      }
    });
  };
  const getDeleteDialog = () => {
    const text = tabValue === 0 ? "파드" : tabValue === 1 ? "디플로이먼트" : "서비스";
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="sm-dialog-header">
            <div className="flexRow N">
              <Icon path={mdiAlertCircle} size="18px" color="#F06262" />
              <p>{text} 삭제</p>
            </div>
          </div>

          <div className="sm-dialog-contents">
            <p>해당 {text}를 삭제 하시겠습니까?</p>
          </div>
          <div className="sm-dialog-btn-wrap">
            <button className="btn cancel-btn" onClick={() => setShowDeleteDialog(false)}>
              취소
            </button>
            <button className="btn submit-btn" onClick={() => deleteAction()}>
              확인
            </button>
          </div>
        </div>
      </div>
    );
  };

  const addApp = () => {
    addMyApp(showAddService).then((result) => {
      if (result) {
        MakeToast("success", "정상적으로 앱이 생성 되었습니다.");
        initAppList();
        setShowAddService(false);
      }
    });
  };

  const goDetail = (v) => {
    setYamlText("");
    setSelectedResource(null);
    getAppDetail(v.appNo).then((detailResp) => {
      setDetailModalData({ appDetail: detailResp, appNo: v.appNo });
      setTabValue(0);
      setShowDetailModal(v.appStatus);
      setRefreshTime(getCurrentDate());
    });
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" className="panel-wrapper flexRow" hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const [getPriorityData, setPriorityData] = useState();
  const [showPriorityDialog, setShowPriorityDialog] = useState(false);
  const getItemStyle = (draggableStyle) => ({
    margin: "0 0 8px 0",
    ...draggableStyle,
  });
  const productListJSX = (item) => {
    return (
      <div className="priority-item flexRow">
        <p className="num">{item.indexNumber}</p>
        <p className="name box">{item.name}</p>
        <p className="key box">{item.key}</p>
        <em className="icon-icon_drag_handle" />
      </div>
    );
  };

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [addDialogData, setAddDialogData] = useState();

  const [selectedResource, setSelectedResource] = useState(null);

  useEffect(() => {
    if (detailModalData?.appDetail.length > 0) {
      const typeArray = ["pod", "deployment", "service"];
      const resourceTmp =
        detailModalData.appDetail.filter((row) => {
          return resourceType[typeArray[tabValue]].includes(row.resourceTypeNo);
        })[0] ?? null;
      setSelectedResource((prev) => {
        if (prev && prev.resourceTypeNo === resourceTmp?.resourceTypeNo) {
          return prev;
        } else {
          setYamlText(resourceTmp?.resourceYaml ?? "");
          return resourceTmp;
        }
      });
    }
  }, [detailModalData, resourceType, tabValue]);

  const getAddDialog = () => {
    const text = tabValue === 0 ? "파드 " : tabValue === 1 ? "디플로이먼트 " : "서비스 ";
    return (
      <div className="modal-wrapper" style={{ zIndex: 9998 }}>
        <div className="modal-box">
          <div className="modal-header">
            <p className="title">{text} 추가</p>
            <IconButton onClick={() => setShowAddDialog(false)}>
              <Icon path={mdiClose} size="20px" color="#fff" />
            </IconButton>
          </div>

          <div className="modal-contents">
            <div className="add-pod-wrap-wrap flexCol">
              {/*<div className="flexCol">
                <p className="label require">{text} 이름</p>
                <input
                  type="text"
                  placeholder="입력하세요."
                  value={addDialogData?.resourceName}
                  onChange={(e) =>
                    setAddDialogData((prev) => {
                      return { ...prev, resourceName: e.target.value };
                    })
                  }
                />
                </div>*/}
              <div className="input-log-wrap">
                <CustomCodeMirror
                  value={addDialogData?.resourceYaml}
                  isEdit
                  updateCallBack={(value) =>
                    setAddDialogData((prev) => {
                      return { ...prev, resourceYaml: value };
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-btn-wrap" style={{ background: "inherit" }}>
            <button className="btn cancel-btn border-btn-main" onClick={() => setShowAddDialog(false)}>
              취소
            </button>
            <button
              className={`btn blue-btn-main`}
              onClick={() => {
                insertResource(addDialogData).then((result) => {
                  if (result) {
                    MakeToast("success", `${text} 저장에 성공하였습니다.`);
                    getAppDetail(addDialogData.mecAppNo).then((detailResp) => {
                      setDetailModalData({
                        appDetail: detailResp,
                        appNo: addDialogData.mecAppNo,
                      });
                    });
                    initAppList();
                    setShowAddDialog(false);
                  }
                });
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    );
  };
  const onDragEnd = useCallback((result, items) => {
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPriorityData(
      items.map((priority, index) => ({
        ...priority,
        indexNumber: index + 1,
      }))
    );
  }, []);

  const getPriorityDialog = useMemo(() => {
    return (
      <div className="modal-wrapper">
        <div className="modal-box">
          <div className="modal-header">
            <p className="title">우선순위 설정</p>
            <IconButton onClick={() => setShowPriorityDialog(false)}>
              <Icon path={mdiClose} size="20px" color="#fff" />
            </IconButton>
          </div>
          <div className="modal-contents">
            <div className="drag-priority">
              <DragDropContext onDragEnd={(result) => onDragEnd(result, [...getPriorityData])}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {getPriorityData
                        .sort((a, b) => (a.indexNumber < b.indexNumber ? -1 : 1))
                        .map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(provided.draggableProps.style)}
                              >
                                {productListJSX(item, index)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className="modal-btn-wrap" style={{ background: "inherit" }}>
            <button className="btn cancel-btn border-btn-main" onClick={() => setShowPriorityDialog(false)}>
              취소
            </button>
            <button
              className={`btn blue-btn-main`}
              onClick={() =>
                updateResourceOrder(getPriorityData).then((result) => {
                  if (result) {
                    MakeToast("success", "우선순위 저장에 성공하였습니다.");
                    getAppDetail(detailModalData.appNo).then((detailResp) => {
                      setDetailModalData({
                        appDetail: detailResp,
                        appNo: detailModalData.appNo,
                      });
                    });
                    initAppList();
                    setShowPriorityDialog(false);
                  }
                })
              }
            >
              저장
            </button>
          </div>
        </div>
      </div>
    );
  }, [detailModalData?.appNo, getPriorityData, initAppList, onDragEnd]);

  const getDetailModal = useCallback(
    (yamlText, setYamlText) => {
      const callDelete = (action, row) => {
        if (showDetailModal === "run") {
          MakeToast("fail", "실행 중인 앱은 편집할 수 없습니다.");
        } else {
          setShowDeleteDialog(row);
        }
      };

      const modalTableHeaderItems = [
        {
          name: "번호",
          key: "resourceNo",
          width: "80px",
          type: "text",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "이름",
          key: "resourceName",
          width: "190px",
          center: "no",
          type: "text",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "우선순위",
          key: "resourceOrder",
          type: "text",
          width: "105px",
          action: (row) => {
            setYamlText(row.resourceYaml ?? "");
            setSelectedResource(row.resourceYaml ? row : null);
          },
        },
        {
          name: "삭제",
          key: "delete",
          type: "delete",
          action: (action, row) => callDelete(action, row),
        },
      ];

      return (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">{"앱 정보"}</p>
              <IconButton onClick={() => setShowDetailModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                </Tabs>
                <div className="modal-tab-btn-wrap flexRow">
                  <button
                    className="modal-tab-btn border-btn-main"
                    onClick={() => {
                      if (showDetailModal === "run") {
                        MakeToast("fail", "실행 중인 앱은 편집할 수 없습니다.");
                      } else {
                        const resourceTypeNo = tabValue === 0 ? resourceType.pod[0] : tabValue === 1 ? resourceType.deployment[0] : resourceType.service[0];
                        const resourceOrder =
                          detailModalData.appDetail && detailModalData.appDetail.length > 0
                            ? Math.max(...detailModalData.appDetail.map((o) => o.resourceOrder)) + 1
                            : 1;

                        const defaultServiceYaml = getDefaultServiceYaml();

                        setAddDialogData({
                          mecAppNo: detailModalData.appNo,
                          resourceName: "",
                          resourceTypeNo: resourceTypeNo,
                          resourceOrder: resourceOrder,
                          resourceYaml: tabValue === 2 ? defaultServiceYaml : "",
                          mecServiceNo: 0,
                        });
                        setShowAddDialog(true);
                      }
                    }}
                  >
                    {tabValue === 0 ? "파드 " : tabValue === 1 ? "디플로이먼트 " : "서비스 "}
                    추가
                  </button>
                  <button
                    className="modal-tab-btn border-btn-main"
                    onClick={() => {
                      if (showDetailModal === "run") {
                        MakeToast("fail", "실행 중인 앱은 편집할 수 없습니다.");
                      } else {
                        let priorityList = [];
                        detailModalData?.appDetail.forEach((row) => {
                          const resourceTypeName = resourceType.pod.includes(row.resourceTypeNo)
                            ? "파드"
                            : resourceType.deployment.includes(row.resourceTypeNo)
                            ? "디플로이먼트"
                            : "서비스";
                          priorityList.push({
                            indexNumber: row.resourceOrder,
                            id: row.resourceNo,
                            name: resourceTypeName,
                            key: row.resourceName,
                          });
                        });
                        setPriorityData(priorityList);
                        setShowPriorityDialog(true);
                      }
                    }}
                  >
                    우선순위 설정
                  </button>
                </div>
              </div>

              <CustomTabPanel value={tabValue} index={0}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData?.appDetail?.filter((row) => {
                      return resourceType.pod.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => {
                      setYamlText(row.resourceYaml ?? "");
                      setSelectedResource(row.resourceYaml ? row : null);
                    }}
                  />
                </div>
                <div className="right-sec" style={{ height: "456px" }}>
                  <div className="input-log-wrap">
                    <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData?.appDetail?.filter((row) => {
                      return resourceType.deployment.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => {
                      setYamlText(row.resourceYaml ?? "");
                      setSelectedResource(row.resourceYaml ? row : null);
                    }}
                  />
                </div>
                <div className="right-sec" style={{ height: "456px" }}>
                  <div className="input-log-wrap">
                    <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData?.appDetail?.filter((row) => {
                      return resourceType.service.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => {
                      setYamlText(row.resourceYaml ?? "");
                      setSelectedResource(row.resourceYaml ? row : null);
                    }}
                  />
                </div>
                <div className="right-sec" style={{ height: "456px" }}>
                  <div className="input-log-wrap">
                    <CustomCodeMirror value={yamlText} isEdit={selectedResource} updateCallBack={(value) => setYamlText(value)} />
                  </div>
                </div>
              </CustomTabPanel>
            </div>
            <div className="modal-btn-wrap" style={{ background: "inherit" }}>
              <button className="btn cancel-btn border-btn-main" onClick={() => setShowDetailModal(false)}>
                취소
              </button>
              <button
                className={`btn blue-btn-main`}
                onClick={() => {
                  if (showDetailModal === "run") {
                    MakeToast("fail", "실행 중인 앱은 편집할 수 없습니다.");
                  } else {
                    if (selectedResource) {
                      updateResource({ ...selectedResource, resourceYaml: yamlText }).then((result) => {
                        if (result) {
                          MakeToast("success", "리소스 수정 성공하였습니다.");
                          const resourceTmp = { ...selectedResource };
                          initAppList();
                          getAppDetail(resourceTmp.mecAppNo).then((detailResp) => {
                            setDetailModalData({ appDetail: detailResp, appNo: resourceTmp.mecAppNo });
                            const targetResource = detailResp.filter((row) => {
                              return row.resourceNo === resourceTmp.resourceNo;
                            })[0];

                            setYamlText(targetResource.resourceYaml);
                            setSelectedResource(targetResource);
                          });
                        }
                      });
                    }
                  }
                }}
              >
                수정
              </button>
            </div>
          </div>
        </div>
      );
    },
    [detailModalData, initAppList, resourceType, selectedResource, showDetailModal, tabValue]
  );

  const modalResourcePodTableHeaderItems = [
    {
      name: "이름",
      key: "name",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "상태",
      key: "status",
      width: "178px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "생성일시",
      key: "regDate",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
  ];

  const modalResourceTableHeaderItems = (type) => {
    return [
      {
        name: "이름",
        key: "name",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "네임스페이스",
        key: "namespace",
        width: "178px",
        center: "no",
        type: "text",
        action: (row) => clickedRow(row, type),
      },
      {
        name: "생성일시",
        key: "regDate",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedRow(row, type),
      },
    ];
  };

  const getDetailHeader = (type) => {
    const headerItem = type === "pod" ? modalResourcePodTableHeaderItems : modalResourceTableHeaderItems(type);

    return headerItem;
  };

  const clickedDetailRow = (row, type) => {
    switch (type) {
      case "deployment":
        setTabDetailData(showResourceModal?.deployment[row.no]);
        break;
      case "service":
        setTabDetailData(showResourceModal?.service[row.no]);
        break;
      case "ingress":
        setTabDetailData(showResourceModal?.ingress[row.no]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[row.no]);
        break;
    }
  };

  const handleResourceTabValueChange = (event, newValue) => {
    switch (newValue) {
      case 1:
        setTabDetailData(showResourceModal?.deployment[0]);
        break;
      case 2:
        setTabDetailData(showResourceModal?.service[0]);
        break;
      case 3:
        setTabDetailData(showResourceModal?.ingress[0]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[0]);
        break;
    }
    setResourceTabValue(newValue);
  };

  return (
    <div className="page">
      {showResourceModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">앱 상태</p>
              <IconButton onClick={() => setShowResourceModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={resourceTabValue} onChange={handleResourceTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                  <Tab label="인그레스" />
                </Tabs>
              </div>

              <CustomTabPanel value={resourceTabValue} index={0}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("pod"))}
                    rowData={showResourceModal?.podList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "pod")}
                  />
                </div>
                {resourceTabValue === 0 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">노드</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.nodeName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">호스트 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.status.hostIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={1}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("deployment"))}
                    rowData={showResourceModal?.deployList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "deployment")}
                  />
                </div>
                {resourceTabValue === 1 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">레플리카셋</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.replicas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">상태</p>
                            </td>
                            <td>
                              <p className="center">
                                {"Ready Replicas: "}
                                {tabDetailData?.status.readyReplicas}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={2}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("service"))}
                    rowData={showResourceModal?.serviceList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "service")}
                  />
                </div>
                {resourceTabValue === 2 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클러스터 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.clusterIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>포트</p>
                            </td>
                            <td>
                              {tabDetailData?.spec?.ports?.map((portRow, idx) => {
                                return (
                                  <div className="black-box" key={"port_" + idx}>
                                    {Object.entries(portRow).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">서비스 타입</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.type}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={resourceTabValue} index={3}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("ingress"))}
                    rowData={showResourceModal?.ingressList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "ingress")}
                  />
                </div>
                {resourceTabValue === 3 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클래스 이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.ingressClassName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">링크</p>
                            </td>
                            <td>
                              {tabDetailData &&
                                tabDetailData.linkList.map((link) => {
                                  return (
                                    <p className="center">
                                      <a href={link.linkHref} target="_blank" rel="noreferrer">
                                        {link.linkName}
                                      </a>
                                    </p>
                                  );
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}
      {showAddDialog && getAddDialog()}
      {showDeleteAppDialog && getDeleteAppDialog()}
      {showDeleteDialog && getDeleteDialog()}
      {showDetailModal && getDetailModal(yamlText, setYamlText)}
      {showPriorityDialog && getPriorityDialog}
      {showAddService && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">MEC 앱 생성</p>
              <IconButton onClick={() => setShowAddService(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="add-service-wrap">
                <div className="flexCol">
                  <p className="label require">앱 이름</p>
                  <input
                    type="text"
                    placeholder="입력하세요."
                    value={showAddService?.appName}
                    onChange={(e) =>
                      setShowAddService((prev) => {
                        return { ...prev, appName: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-btn-wrap" style={{ background: "inherit" }}>
              <button className="btn cancel-btn border-btn-main" onClick={() => setShowAddService(false)}>
                취소
              </button>
              <button className={`btn ${false ? "error" : ""} border-btn-main`} onClick={() => addApp()}>
                생성
              </button>
            </div>
          </div>
        </div>
      )}
      {showAgreeDialog && getAgreeDialog()}
      <div className="page-inner">
        <div className="mymec-service-wrap">
          <ComponentHeader title="내 MEC 앱" addBtn={addService} />
          <div className="app-mgmt-header box-info flexRow">
            <div className="left-wrap flexRow">
              <div className="info flexRow">
                <p className="title">MEC 앱</p>
                <p className="value">{getCommaNumber(statusCount && statusCount[0].value)}</p>
              </div>
            </div>
            <div className="right-wrap flexRow">
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  초기화
                </p>
                <p className="value">{getCommaNumber(statusCount?.[1].value)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#15EA6A" }} />
                  실행중
                </p>
                <p className="value">{getCommaNumber(statusCount?.[2].value)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span />
                  종료
                </p>
                <p className="value">{getCommaNumber(statusCount?.[3].value)}</p>
              </div>
            </div>
          </div>
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={myAppData} perPage={15} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyMecApp;
