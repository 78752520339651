import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import ComponentHeader from "components/componentheader/ComponentHeader";
import CustomTable from "components/customtable/CustomTable";
import LogViewer from "components/logviewer/LogViewer";
import { getAppDetail, getAppList, getCurrentDate, getResourceType, getRunningAppDetail } from "data/CommonFunction";
import { refreshTimeAtom } from "data/Store";
import getCommaNumber from "hooks/GetCommaNumber";
import getTableHeader from "hooks/GetTableHeader";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import "./MgmtApp.scss";

export const MgmtApp = () => {
  const [tabValue, setTabValue] = useState(0);
  const [mgmtAppData, setMgmtAppData] = useState([]);
  const [statusCount, setStatusCount] = useState({
    user: 0,
    app: 0,
    init: 0,
    run: 0,
    stop: 0,
  });
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState([]);
  const [resourceType, setResourceType] = useState({
    pod: [],
    deployment: [],
    service: [],
  });
  const [yamlText, setYamlText] = useState("");
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [tabDetailData, setTabDetailData] = useState();
  const headerItems = [
    {
      name: "번호",
      key: "appNo",
      width: "90px",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "앱 이름",
      key: "appName",
      width: "500px",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "사용자",
      key: "userNsName",
      center: "no",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "파드",
      key: "appPodNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "디플로이먼트",
      key: "appDeploymentNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "서비스",
      key: "appServiceNum",
      type: "text",
      action: (row) => clickedRow(row),
    },
    {
      name: "앱 상태",
      key: "appStatus",
      type: "appStatus",
      action: (row) => {
        getRunningAppDetail(row.mecUserNo, row.appNo).then((detailResp) => {
          setTabValue(0);
          setTabDetailData(detailResp.pod[0]);
          setShowResourceModal(detailResp);
        });
      },
    },
    {
      name: "",
      key: "action",
      center: "no",
      width: "115px",
      type: "detail",
      action: (row) => goDetail(row),
    },
  ];
  const modalTableHeaderItems = [
    {
      name: "번호",
      key: "resourceNo",
      width: "90px",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
    {
      name: "이름",
      key: "resourceName",
      width: "250px",
      center: "no",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
    {
      name: "우선순위",
      key: "resourceOrder",
      type: "text",
      action: (row) => setYamlText(row.resourceYaml || ""),
    },
  ];
  const clickedRow = (v) => {
    console.debug(v);
  };

  const goDetail = (v) => {
    setYamlText("");
    getAppDetail(v.appNo).then((detailResp) => {
      setTabValue(0);
      setDetailModalData(detailResp);
      setShowDetailModal(true);
      setRefreshTime(getCurrentDate());
    });
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (detailModalData.length > 0) {
      switch (tabValue) {
        case 0:
        default:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.pod.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
        case 1:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.deployment.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
        case 2:
          setYamlText(
            detailModalData.filter((row) => {
              return resourceType.service.includes(row.resourceTypeNo);
            })[0]?.resourceYaml || ""
          );
          break;
      }
    }
  }, [detailModalData, resourceType, tabValue]);

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" className="panel-wrapper flexRow" hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const modalResourcePodTableHeaderItems = [
    {
      name: "이름",
      key: "name",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "상태",
      key: "status",
      width: "178px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
    {
      name: "생성일시",
      key: "regDate",
      width: "200px",
      center: "no",
      type: "text",
      action: (row) => clickedDetailRow(row, "pod"),
    },
  ];

  const modalResourceTableHeaderItems = (type) => {
    return [
      {
        name: "이름",
        key: "name",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "네임스페이스",
        key: "namespace",
        width: "178px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
      {
        name: "생성일시",
        key: "regDate",
        width: "200px",
        center: "no",
        type: "text",
        action: (row) => clickedDetailRow(row, type),
      },
    ];
  };

  const getDetailHeader = (type) => {
    const headerItem = type === "pod" ? modalResourcePodTableHeaderItems : modalResourceTableHeaderItems(type);

    return headerItem;
  };

  const clickedDetailRow = (row, type) => {
    switch (type) {
      case "deployment":
        setTabDetailData(showResourceModal?.deployment[row.no]);
        break;
      case "service":
        setTabDetailData(showResourceModal?.service[row.no]);
        break;
      case "ingress":
        setTabDetailData(showResourceModal?.ingress[row.no]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[row.no]);
        break;
    }
  };

  const handleResourceTabValueChange = (event, newValue) => {
    switch (newValue) {
      case 1:
        setTabDetailData(showResourceModal?.deployment[0]);
        break;
      case 2:
        setTabDetailData(showResourceModal?.service[0]);
        break;
      case 3:
        setTabDetailData(showResourceModal?.ingress[0]);
        break;
      default:
        setTabDetailData(showResourceModal?.pod[0]);
        break;
    }
    setTabValue(newValue);
  };

  const setRefreshTime = useSetAtom(refreshTimeAtom);

  useEffect(() => {
    getAppList().then((appResp) => {
      setStatusCount(appResp.appStat);
      setMgmtAppData(appResp.appData);
    });

    getResourceType().then((resourceTypeResp) => {
      setResourceType(resourceTypeResp);
    });

    setRefreshTime(getCurrentDate());
  }, [setRefreshTime]);

  return (
    <div className="page">
      {showResourceModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">앱 상태</p>
              <IconButton onClick={() => setShowResourceModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleResourceTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                  <Tab label="인그레스" />
                </Tabs>
              </div>

              <CustomTabPanel value={tabValue} index={0}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("pod"))}
                    rowData={showResourceModal?.podList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "pod")}
                  />
                </div>
                {tabValue === 0 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">노드</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.nodeName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">호스트 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.status.hostIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("deployment"))}
                    rowData={showResourceModal?.deployList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "deployment")}
                  />
                </div>
                {tabValue === 1 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">레플리카셋</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.replicas}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">상태</p>
                            </td>
                            <td>
                              <p className="center">
                                {"Ready Replicas: "}
                                {tabDetailData?.status.readyReplicas}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>컨디션</p>
                            </td>
                            <td>
                              {tabDetailData?.status?.conditions?.map((condition, idx) => {
                                return (
                                  <div className="black-box" key={"cond_" + idx}>
                                    {Object.entries(condition).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("service"))}
                    rowData={showResourceModal?.serviceList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "service")}
                  />
                </div>
                {tabValue === 2 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클러스터 IP</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.clusterIP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>포트</p>
                            </td>
                            <td>
                              {tabDetailData?.spec?.ports?.map((portRow, idx) => {
                                return (
                                  <div className="black-box" key={"port_" + idx}>
                                    {Object.entries(portRow).map(([key, value]) => {
                                      return (
                                        <div className="black-box-line flexRow" key={"cond_" + idx + key}>
                                          <span className="key">{key}</span>
                                          <span className="value">{value ?? "null"}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">서비스 타입</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.type}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3}>
                <div className="left-sec2">
                  <CustomTable
                    header={getTableHeader(getDetailHeader("ingress"))}
                    rowData={showResourceModal?.ingressList}
                    perPage={7}
                    rowClickCallBack={(row) => clickedDetailRow(row, "ingress")}
                  />
                </div>
                {tabValue === 3 && (
                  <div className="right-sec2">
                    <div className="data-table-viewer">
                      <table>
                        <tbody>
                          <tr>
                            <td className="table-key">
                              <p className="center">이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.metadata.name}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p>라벨</p>
                            </td>
                            <td>
                              <div className="black-box">
                                {tabDetailData &&
                                  Object.entries(tabDetailData.metadata.labels).map(([key, value]) => {
                                    return (
                                      <div className="black-box-line flexRow" key={"label_" + key}>
                                        <span className="key">{key}</span>
                                        <span className="value">{value ?? "null"}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">클래스 이름</p>
                            </td>
                            <td>
                              <p className="center">{tabDetailData?.spec.ingressClassName}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="table-key">
                              <p className="center">링크</p>
                            </td>
                            <td>
                              {tabDetailData &&
                                tabDetailData.linkList.map((link) => {
                                  return (
                                    <p className="center">
                                      <a href={link.linkHref} target="_blank" rel="noreferrer">
                                        {link.linkName}
                                      </a>
                                    </p>
                                  );
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}
      {showDetailModal && (
        <div className="modal-wrapper">
          <div className="modal-box">
            <div className="modal-header">
              <p className="title">앱 정보</p>
              <IconButton onClick={() => setShowDetailModal(false)}>
                <Icon path={mdiClose} size="20px" color="#fff" />
              </IconButton>
            </div>
            <div className="modal-contents">
              <div className="tab-wrapper flexRow">
                <Tabs value={tabValue} onChange={handleTabValueChange}>
                  <Tab label="파드" />
                  <Tab label="디플로이먼트" />
                  <Tab label="서비스" />
                </Tabs>
              </div>

              <CustomTabPanel value={tabValue} index={0}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.pod.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.deployment.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <div className="left-sec">
                  <CustomTable
                    header={getTableHeader(modalTableHeaderItems)}
                    rowData={detailModalData.filter((row) => {
                      return resourceType.service.includes(row.resourceTypeNo);
                    })}
                    perPage={7}
                    rowClickCallBack={(row) => setYamlText(row.resourceYaml || "")}
                  />
                </div>
                <div className="right-sec">
                  <LogViewer log={yamlText} />
                </div>
              </CustomTabPanel>
            </div>
          </div>
        </div>
      )}
      <div className="page-inner">
        <div className="mgmtapp-wrap flexCol">
          <ComponentHeader title="앱 관리" />
          <div className="app-mgmt-header box-info flexRow">
            <div className="left-wrap flexRow">
              <div className="info flexRow">
                <p className="title">앱 생성 사용자</p>
                <p className="value">{getCommaNumber(statusCount.user)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">MEC 앱</p>
                <p className="value">{getCommaNumber(statusCount.app)}</p>
              </div>
            </div>
            <div className="right-wrap flexRow">
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#FFD43B" }} />
                  초기화
                </p>
                <p className="value">{getCommaNumber(statusCount.init)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span style={{ background: "#15EA6A" }} />
                  실행중
                </p>
                <p className="value">{getCommaNumber(statusCount.run)}</p>
              </div>
              <div className="info flexRow">
                <p className="title">
                  <span />
                  종료
                </p>
                <p className="value">{getCommaNumber(statusCount.stop)}</p>
              </div>
            </div>
          </div>
          <div className="table-scroll-wrap">
            <CustomTable header={getTableHeader(headerItems)} rowData={mgmtAppData} perPage={15} rowClickCallBack={clickedRow} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MgmtApp;
